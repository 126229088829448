<template>
  <!-- Project Area Start -->
  <section class="ekifa-project-area section_80">
    <b-container>
      <b-row>
        <b-col>
          <div class="site-heading">
            <h2>Our Practices</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis accumsan nisi Ut ut felis congue nisl hendrerit commodo. </p>
          </div>
        </b-col>
      </b-row>
      <div class="portfolio-warp">
        <ul class="portfolio-filter">
          <li :class="`filter ${item.isActive ? 'active' : ''}`" v-for="(item, key) in categories" :key="key" @click="projectHandler(item.type)">{{item.name}}</li>
        </ul>
        <b-row class="isotope_items">
          <b-col lg="4" md="6" v-for="(item, key) in projects" :key="key">
              <div class="work-item work-image" @click="viewPraticeServices(item)">
                <div class="project-img">
                    <img :src="require(`../assets/img/${item.img}`)" alt="project">
                </div>
                <div class="content">
                  <p>{{item.name}}</p>
                </div>
              </div>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <div class="load-btn">
            <b-link class="ekifa-btn">load more projects</b-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
  <!-- Project Area End -->
</template>

<script>
import {mapActions} from "vuex";

const projects = require('../json/projects.json')
export default {
  name: "ProjectArea",
  data() {
    return {
      categories: [
        {
          name: 'All Works',
          type: 'all',
          isActive: true
        },
        {
          name: 'Corporate and Commercial Law',
          type: 'CorporateAndCommercialLaw',
          isActive: false
        },
        {
          name: 'Intellectual Property Law',
          type: 'IntellectualPropertyLaw',
          isActive: false
        },
        {
          name: 'Banking and Finance',
          type: 'BankingAndFinance',
          isActive: false
        },
        {
          name: 'Construction and Engineering',
          type: 'ConstructionAndEngineering',
          isActive: false
        },
        {
          name: 'Property and Real Estate',
          type: 'PropertyAndRealEstate',
          isActive: false
        },
        {
          name: 'Maritime Trade and Insurance',
          type: 'MaritimeTradeAndInsurance',
          isActive: false
        },
        {
          name: 'Disputes, Litigation and Arbitration',
          type: 'DisputesLitigationAndArbitration',
          isActive: false
        },
        {
          name: 'Criminal Law',
          type: 'CriminalLaw',
          isActive: false
        },
        {
          name: 'Labor Law',
          type: 'LaborLaw',
          isActive: false
        },
        {
          name: 'Tax',
          type: 'Tax',
          isActive: false
        },
        {
          name: 'Family Law',
          type: 'FamilyLaw',
          isActive: false
        },
        {
          name: 'Power and Energy',
          type: 'PowerAndEnergy',
          isActive: false
        }
      ],
      OriginalProjects: projects,
      projects: projects.filter((element) => element.type === "all")
    }
  },
  methods: {
    ...mapActions(["updatePractice"]),
    projectHandler(type) {
      this.projects = projects;
      this.categories = this.categories.map((cat) => {
        return {
          ...cat,
          isActive:  type === cat.type
        }
      });
      this.projects = this.projects.filter((data) => data.type === type);
    },
    viewPraticeServices(item) {
      if (item.type === 'all') {
        this.updatePractice(projects.find((element) => element.name === item.name));
        this.$router.push({
          name: 'PracticeServices',
          params: { 
            idPractice: item.name.replace(/\s+/g, ''),
            item: item,
            practices: this.OriginalProjects.filter((element) => element.id === item.id).slice(1)
          }
        })
      } else {
        this.$router.push({
          name: 'PracticeServiceDetails',
          params: {
            idPractice: item.type,
            idService: item.name.replace(/\s+/g, ''),
            item: item }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>