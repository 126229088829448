<template>
<div>
  <!-- All Services Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Service Area Component -->
  <AllPractices/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import AllPractices from "../../components/AllPractices";
export default {
  name: "AllServices",
  components: {AllPractices, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: 'Practices',
        pageTitle: 'All Practices'
      }
    }
  }
}
</script>

<style scoped>

</style>